function isLoginOrSignUp() {
    return window.location.pathname === "/login/" || window.location.pathname === "/sign-up/" || window.location.pathname === "/forgot-password/";
}

firebase.auth().onAuthStateChanged(async function (data) {
    if (!data && !isLoginOrSignUp()) {
        window.location = "/login/";
    }
        
    if (sensePathApp) {
        sensePathApp.authData = data;
        if(data) {
            await getStripeRole(data);
        }
    }
});

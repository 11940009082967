// @codekit-prepend "/js/firebase-init.js";
// @codekit-prepend "/vendor/vue.js";
// @codekit-prepend "/js/utils.js";

firebase.auth().onAuthStateChanged(async function (data) {
    if (sensePathApp) {
        getPrices();
        getPreviousSubscriptions();
        ref.child("users")
            .child(data.uid)
            .child('pupils')
            .on("value", function (snapshot) {
                var value = snapshot.val();
                sensePathApp.userData = {
                    uid: data.uid,
                    pupils: value,
                    isAdmin: false
                };
                sensePathApp.totalProfiles = snapshot.numChildren();
                if (sensePathApp.totalProfiles === 0) {
                    $('#create-first-profile-modal').modal('show');
                }
            }
            );
        toggleLoading(false);
    }
});

var sensePathApp = new Vue({
    el: "#sense-path-app",
    data: function () {
        return {
            authData: null,
            userData: {
                uid: "",
                pupils: {}
            },
            stripeRole: null,
            profileLimit: 2,
            totalProfiles: 0,
            pupilFilter: "",
            products: [],
            sortedPupils: [],
            sortBy: 'newest',
            firstYearDiscount: false
        };
    },
    methods: {
        logout: function () {
            console.log("logging out");
            firebase
                .auth()
                .signOut()
                .then(function () {
                    // Sign-out successful.
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        isInFilter: function (name, query) {
            if (name && query) {
                return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            } else {
                return true;
            }
        },
        openCheckout: openCheckout,
        openPortal: openPortal,
        canAddProfiles: canAddProfiles
    },
    filters: {
        price: priceFilter
    },
    watch: {
        sortBy: function () {
            if (this.sortBy === 'oldest') {
                this.sortedPupils = this.sortedPupils.sort(function (a, b) {
                    return compare(a, b, 'id');
                });
            } else if (this.sortBy === 'newest') {
                this.sortedPupils = this.sortedPupils.sort(function (a, b) {
                    return compare(b, a, 'id');
                });
            } else if (this.sortBy === 'nameFirstToLast') {
                this.sortedPupils = this.sortedPupils.sort(function (a, b) {
                    return compare(a, b, 'childName');
                });
            } else if (this.sortBy === 'nameLastToFirst') {
                this.sortedPupils = this.sortedPupils.sort(function (a, b) {
                    return compare(b, a, 'childName');
                });
            }
        },
        userData: function () {
            var pupils = this.userData.pupils;
            if (pupils) {
                var pupilArray = [];
                for (var key in pupils) {
                    var pupil = pupils[key];
                    pupil.id = key;
                    pupilArray.unshift(pupil);
                }
                this.sortedPupils = pupilArray;
            }
        }
    }
});
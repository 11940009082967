async function getPreviousSubscriptions() {
    const docRef = await firestore
        .collection('customers')
        .doc(sensePathApp.authData.uid)
        .collection('subscriptions');
    const snap = await docRef.get();
    if (snap.empty) {
        sensePathApp.firstYearDiscount = true;
    }
}

async function openCheckout(price) {
    toggleLoading(true);
    try {
        const docRef = await firestore
            .collection('customers')
            .doc(this.authData.uid)
            .collection('checkout_sessions')
            .add({
                promotion_code: sensePathApp.firstYearDiscount && "promo_1PxSZKG6qftjPD9DJafGseVJ",
                price: price,
                success_url: window.location.origin,
                cancel_url: window.location.origin
            });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            const { error, url } = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        });
    } catch (e) {
        console.error(e);
        toggleLoading(false);
    }
}

async function openPortal() {
    toggleLoading(true);
    try {
        const functionRef = firebase
            .app()
            .functions('europe-west2')
            .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
        const { data } = await functionRef({
            returnUrl: window.location.origin,
            locale: "auto" // Optional, defaults to "auto"
        });
        window.location.assign(data.url);
    } catch (e) {
        console.error(e);
        toggleLoading(false);
    }
}

function getPrices() {
    firestore.collection('products')
        .where('active', '==', true)
        .get()
        .then(async function (querySnapshot) {
            const productPromises = querySnapshot.docs.map(async (doc) => {
                const product = {
                    name: doc.data().name,
                    profile_limit: doc.data().role,
                    prices: {
                        yearly: { id: null, amount: null },
                        monthly: { id: null, amount: null }
                    }
                };
                const priceSnap = await doc.ref.collection('prices').get();
                priceSnap.docs.forEach((priceDoc) => {
                    if (priceDoc.data().interval === 'year') {
                        product.prices.yearly.id = priceDoc.id;
                        product.prices.yearly.amount = priceDoc.data().unit_amount;
                    } else {
                        product.prices.monthly.id = priceDoc.id;
                        product.prices.monthly.amount = priceDoc.data().unit_amount;
                    }
                });
                return product; // Return the product to be collected in the Promise.all
            });

            const productsArray = await Promise.all(productPromises); // Wait for all products to be created
            productsArray.sort((a, b) => a.profile_limit - b.profile_limit); // Sort using profile_limit
            sensePathApp.products = productsArray; // Assign the sorted array to sensePathApp.products
        })
        .catch((error) => {
            console.error("Error fetching products: ", error);
        });
}
